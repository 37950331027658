module.exports = {
    en: {
      path: `en`,
      locale: `en-US`,
      dateFormat: `DD/MM/YYYY`,
      siteLanguage: `en`,
      ogLanguage: `en_US`,
      defaultTitle: `Effemera`,
      defaultDescription: `Some description`,
    },
    pl: {
      default: true,
      path: `pl`,
      locale: `pl-PL`,
      dateFormat: `DD.MM.YYYY`,
      siteLanguage: `pl`,
      ogLanguage: `pl_PL`,
      defaultTitle: `Effemera`,
      defaultDescription: `Jakiś opis`,
    },
  }