import React from "react";
import { Link } from "gatsby"

const Footer = () => {

  return (
    <div className="w-screen text-base tracking-widest text-eff-blue">
      <div className="px-4 py-4 max-w-[1314px] m-auto flex flex-col-reverse md:flex-row justify-between gap-2"><h1 to="/">
       ©2023 effemera
      </h1>
      <a href="mailto:hello@effemera.com">
        hello@effemera.com ↗
      </a>
      <a href="https://instagram.com/effemera.press">
        INSTAGRAM ↗
      </a>
    </div>
    <div className="h-5 bg-[url('../images/stripe.svg')]"></div>
    </div>
  );
};

export default Footer;
