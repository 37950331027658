import React from "react"
import {
    container,
    stamp,
    stampRound,
    sygnet,
    ribbonText
} from './ribbon.module.css'

const Ribbon = () => {
  return (
    <div className={container}>

    </div>
  )
}

export default Ribbon