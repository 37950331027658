import React from "react"
import Navigation from "./navigation"
import Ribbon from "./ribbon"
import Hero from "./hero"
import Footer from "./footer"
import { useStaticQuery, graphql } from 'gatsby'
import {
    container,
    heading,
    siteTitle
} from './layout.module.css'

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageTitle, pageContext: { locale } }) => {
    const data = useStaticQuery(graphql`
        query {
        site {
            siteMetadata {
                title
            }
        }
      }`
    )

    return (
        <LocaleContext.Provider value={{ locale }}>
            <div className="flex flex-col h-screen">
                <header className="h-10">
                    <Ribbon />
                </header>
                <main className="flex-grow flex justify-center items-center py-2 px-2">
                    {children}
                </main>
                <footer className="h-15">
                    <Footer></Footer>
                </footer>
            </div>
        </LocaleContext.Provider>
    )
}

export { Layout, LocaleContext }